import * as React from "react";
import classNames from "classnames";
import { Link } from "gatsby";

const RecommendationHome = ({ data, name }) => {
  let category = name;
  let moreLink={
    New: '/new-dresses',
    Formal: '/formal-dresses',
    Maxi: '/maxi-dresses',
    Midi: '/midi-dresses',
    Mini: '/mini-dresses',
    Party: '/party-dresses',
    Bridesmaid: '/bridesmaid-dresses',
    Sales: '/sales-dresses'
  }
    
  let getRecommendation = (data) => {
    return data.slice(0, 6).map((e, index) => {        
        return e.node.data.product_title != null ? (
                <div className="recommendation-product">                    
                  <a
                  class="hover:opacity-75"
                    href={`/products/cd-${e.node.data.product_title                      
                        .trim()
                        .toLowerCase()
                        .replace(/\s+/g, "-")}`}
                  >
                    <img loading="lazy" fetchpirority="low" src={e.node.data.product_image} alt={e.node.data.product_title} />
                  </a>
                
                  <a
                    className="product-link "
                    href={`/products/cd-${e.node.data.product_title
                      .trim()
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`}
                  >
                    <p className="font-bold hover:opacity-75">
                      CD-
                      {e.node.data.product_title
                        .split(/\b/)
                        .reverse()
                        .join("")}
                    </p>
                  </a>
        
                  {/* <span>{data.company}</span> */}
                  <p className="mb-2">
                    {e.node.data.product_price}
                  </p>
                  
                </div>
              ): null; 
    });
  };

  return (
    <div className="home-recommendation-container pt-4 pb-0 mt-4">    
        <h2 class="text-2xl font-extrabold">{category} Dresses</h2>        
        <div className="home-recommendation-product-container">

        {data != null ? getRecommendation(data) : null}            
            
        </div>    
        <Link className="home-recommendation-more-btn" to={moreLink[category]}>View More</Link>
        
    </div>
  );
};

export default RecommendationHome;
