import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import RecommendationHome from "../components/recommendation-home"

const Index = ({ data, location }) => {

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
  }

  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  const dressAll = shuffleArray(data.dressAll.edges)
  const dressFormal = shuffleArray(data.dressFormal.edges)
  const dressMaxi = shuffleArray(data.dressMaxi.edges)
  const dressMidi = shuffleArray(data.dressMidi.edges)
  const dressMini = shuffleArray(data.dressMini.edges)
  const dressParty = shuffleArray(data.dressParty.edges)
  const dressBridesmaid = shuffleArray(data.dressBridesmaid.edges)
  const dressSales = shuffleArray(data.dressSales.edges)


  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>Collective Dress - Newest and hottest dress - your ultimate dress destination.  </title>
        <link rel="canonical" href="https://www.collectivedress.com/" />      
        <meta name="description" content="Skip the endless search for the latest dress styles across numerous stores with Collective Dress. Our platform combines the trendiest designs from various sources into one convenient stop for all your dress desires. Get the newest, hottest dress styles effortlessly with us."/>
  
  <script type="application/ld+json">
          {`
            {
              "@context": "http://www.schema.org",
              "@type": "Organization",
              "name": "Collective Dress",
              "url": "https://www.collectivedress.com",
              "logo": "https://www.collectivedress.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "image": "https://www.collectivedress.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "description": "You'll find the biggest and best selection of women's dresses in Collective dress. Every week, we add and refresh new styles in our collections. We aggregate the newest and hottest dresses from different sources,  so you don't need to spend countless hours browsing different stores  and boutiques to discover it."
            }
        `}
        </script>
      </Helmet>
      <div>
      <h1 class="home-intro">At Collective dress, we save you the hassle of spending endless hours searching various stores and boutiques for the latest and trendiest dresses.</h1>
      <h2 class="home-intro">Our website brings together the newest and hottest dress styles from different sources, providing you with a convenient one-stop destination for all your dress needs.</h2>
      <RecommendationHome data={dressAll} name="New"/>
      <RecommendationHome data={dressFormal} name="Formal"/>
      <RecommendationHome data={dressMaxi} name="Maxi"/>
      <RecommendationHome data={dressMidi} name="Midi"/>
      <RecommendationHome data={dressMini} name="Mini"/>
      <RecommendationHome data={dressParty} name="Party"/>
      <RecommendationHome data={dressBridesmaid} name="Bridesmaid"/>
      <RecommendationHome data={dressSales} name="Sales"/>
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    dressAll: allAirtable(limit: 50, filter: { table: { eq: "dresses_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressFormal: allAirtable(limit: 50, filter: { table: { eq: "dress_formal" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressMaxi: allAirtable(limit: 50, filter: { table: { eq: "dress_maxi" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }        
    dressMidi: allAirtable(limit: 50, filter: { table: { eq: "dress_midi" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressMini: allAirtable(limit: 50, filter: { table: { eq: "dress_mini" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressParty: allAirtable(limit: 50, filter: { table: { eq: "dress_party" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressBridesmaid: allAirtable(limit: 50, filter: { table: { eq: "dress_bridesmaid" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
    dressSales: allAirtable(limit: 50, filter: { table: { eq: "dress_sales" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
  }
`
